import React, { useState } from "react";
import { View } from "./Subitem";
export function OneBoxRectangle(props) {
  return (
    <div className="one-box-rectangle">
      <div className="one-box-rectangle-title">
        <div className="one-box-rectangle-title-text padding-topleft-corner">
          <b>{props.titleKey}</b>
        </div>
      </div>
      <div className="one-box-rectangle-content">
        <div className={"one-box-rectangle-bottom " + props.style}>
          {props.titlValue}
        </div>
      </div>
    </div>
  );
}

export function OneBoxSquare({
  squaretitleStyle,
  titleKey,
  squareStyle,
  titleValue,
}) {
  return (
    <div className="one-box-square">
      <div className={squaretitleStyle}>{titleKey}</div>
      <div className={"one-box-square-value " + squareStyle}>{titleValue}</div>
    </div>
  );
}

export function RemarkAndScore(props) {
  return (
    <div className="remarkAndScore-Base">
      <div className="remarkAndScore-title">
        <div className="remarkAndScore-title-text">{props.titleKey}</div>
      </div>
      <div className="remarkAndScore-content">
        <div className="remarkAndScore-content-text">{props.titleValue}</div>
      </div>
    </div>
  );
}

export function TwoBarChart(props) {
  return (
    <div className="TwoBarChart-Base onecolumn fullsize">
      <div className="TwoBarChart-inline onecolumn _90size">
        <div className="TwoBarChart-title padding-topleft-corner">
          <b>{props.titleKey}</b>
        </div>
        <div className="TwoBarChart-content">
          <div className="TwoBarChart-content-left">
            <div className="TwoBarChart-content-left-barChart">
              <div className="TwoBarChart-content-left-barChart-column">
                <b>{props.barChartoneTitle}</b>
                <b>{props.barCharttwoTitle}</b>
              </div>
              <div className="TwoBarChart-content-left-barChart-column">
                <div className="barChart-base">
                  <div
                    style={{
                      width: props.barOnenumber,
                      height: "inherit",
                      backgroundColor: "rgb(160, 50, 142)",
                      borderRadius: "2px",
                    }}
                  ></div>
                </div>
                <div className="barChart-base">
                  <div
                    style={{
                      width: props.barTwonumber,
                      height: "inherit",
                      backgroundColor: "rgb(255, 166, 0)",
                      borderRadius: "2px",
                    }}
                  ></div>
                </div>
                <div></div>
              </div>
              <div className="TwoBarChart-content-left-barChart-column right-align">
                <b>{props.barChartoneCount}</b>
                <b>{props.barCharttwoCount}</b>
              </div>
            </div>
          </div>
          <div className="TwoBarChart-content-right">
            <div className="TwoBarChart-content-right-title">
              <b>{props.rightTitle}</b>
            </div>
            <div className="TwoBarChart-content-right-content">
              <b>{props.rightContent}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PieChart(props) {
  const [isSelected, setisSelected] = useState(false);
  const close = () => {
    setisSelected(false);
  };

  return (
    <div className="PieChart-Base onecolumn fullsize">
      <div className="PieChart-Base-inline fullsize onecolumn ">
        <div className="PieChart-title padding-topleft-corner">
          <b>{props.titleKey}</b>
        </div>
        <div className="PieChar-pie">
          <div
            className="PieChart-content-pie"
            style={{
              backgroundImage: props.style,
            }}
          >
            <div className="PieChart-content-pie-inside">
              <div className="PieChart-content-pie-inside_text">
                <b>{props.sum}</b>
              </div>
            </div>
          </div>
        </div>
        <div
          className="PieChart-bottle"
          onClick={() => {
            setisSelected(true);
          }}
        >
          <b>
            <u>{props.bottleKey}</u>
          </b>
        </div>
        {isSelected ? (
          <View
            leftTitle="Requirements"
            rightTitle="Candidate"
            data={props.SkillSet}
            close={close}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
