import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import LHSB from "./LHSBar.js";
import RHSContent from "./RHSContent.js";
import "../../styles/AnalysisDashboard.css";
import emptyData from "./emptyAnalysis.json";
import { useQuery } from "@tanstack/react-query";

function AnalysisDashboard() {
  let { id } = useParams();
  const [data, setData] = useState(emptyData);
  const [candidateData, setCandidateData] = useState(null);

  useEffect(() => {
    if (!candidateData) {
      refetch();
    }
  }, [candidateData]);

  const { data: retrieveData, refetch } = useQuery(
    ["analysis-result"],
    () =>
      axios.get(`/candidate/${id}/analysis`).then((response) => response.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCandidateData(data);
        setData(data);
      },
    }
  );

  return (
    <>
      <div className="analysis-dashboard-top-bar">
        <svg
          width="180"
          height="50"
          preserveAspectRatio="xMidYMid meet"
          data-bbox="9.706 10.996 180.414 37.234"
          viewBox="0 0 199.83 59.23"
          xmlns="http://www.w3.org/2000/svg"
          data-type="ugc"
          role="presentation"
          aria-hidden="true"
        >
          <g>
            <defs>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                y2="11"
                x2="31.66"
                y1="48.24"
                x1="31.66"
                id="ff6c8d05-e986-491f-9262-2a3df4e976f9_comp-lm09f1xd"
              >
                <stop stopColor="#9515ff" offset="0"></stop>
                <stop stopColor="#5177ff" offset="1"></stop>
              </linearGradient>
            </defs>
            <path
              fill="#2d2d2d"
              d="M76.15 36.53h-9.14l-1.27 3.45h-4.49l8.22-20.69h4.27l8.2 20.69h-4.49l-1.3-3.45Zm-7.65-3.91h6.17l-3.06-8.29-3.1 8.29Z"
            ></path>
            <path
              fill="#2d2d2d"
              d="M83.89 19.27h4.33v20.71h-4.33V19.27Z"
            ></path>
            <path
              fill="#2d2d2d"
              d="m94.48 19.27 8.85 10.27 8.85-10.27h2.35v20.71h-4.32V27.26l-6.89 8.1-6.89-8.1v12.72h-4.32V19.27h2.35Z"
            ></path>
            <path
              fill="#2d2d2d"
              d="M118.43 19.28h8.43c1.65 0 2.74.27 3.67.78 1.83 1.01 3.06 2.87 3.06 5.33 0 1.29-.49 2.44-1.34 3.41 1.79 1 3.1 2.73 3.1 4.98 0 2.4-1.18 4.29-2.96 5.33-.95.55-2.08.87-3.78.87h-10.18V19.29Zm7.99 8.27c.82 0 1.2-.06 1.57-.2.84-.35 1.49-1 1.49-2.01s-.65-1.65-1.49-2.01c-.35-.16-.72-.22-1.59-.22h-3.65v4.43h3.67Zm1.34 8.49c.9 0 1.33-.07 1.73-.23.88-.36 1.6-1.08 1.6-2.21 0-1.05-.68-1.82-1.6-2.18-.39-.16-.82-.23-1.76-.23h-4.98v4.85h5.01Z"
            ></path>
            <path
              fill="#2d2d2d"
              d="M152.79 19.27v3.99h-10.24v3.41h7.81v3.94h-7.81V36h10.24v3.99h-14.57V19.28h14.57Z"
            ></path>
            <path
              fill="#2d2d2d"
              d="M157.53 33.29c1.72 1.57 3.88 2.67 6.15 2.67 1.76 0 3.73-.72 3.73-2.31 0-1.94-2.99-2.18-6.04-2.82-3.47-.72-6.24-2.17-6.24-5.49s2.64-6.09 7.36-6.09c2.61 0 5.34.88 7.64 2.69l-2.45 3.08c-1.37-1.16-3.38-1.85-5.24-1.85-1.52 0-3.12.55-3.12 1.79 0 1.36 1.7 1.75 4.64 2.24 3.48.58 7.7 1.75 7.7 6.44 0 3.65-3.45 6.34-7.9 6.34-2.76 0-5.95-1.01-8.82-3.58l2.6-3.1Z"
            ></path>
            <path
              fill="#2d2d2d"
              d="M190.12 19.27v4h-6.82v16.71h-4.33V23.27h-6.8v-4h17.95Z"
            ></path>
            <path
              fill="url(#ff6c8d05-e986-491f-9262-2a3df4e976f9_comp-lm09f1xd)"
              d="m52.98 41.55-8.65-14.29-8.42-13.91c-.94-1.55-2.58-2.34-4.23-2.35s-.02 0-.02 0h-.02c-1.65.02-3.29.8-4.23 2.35l-8.42 13.91-8.65 14.29c-1.8 2.97.45 6.68 4.04 6.68h3.75c2.95 0 4.51-1.13 6.16-3.48 1.58-2.25 2.56-4.75 3.94-6.36.15-.17.11-.44-.08-.56a6.618 6.618 0 0 1-3.1-5.68c.04-3.69 3.16-6.67 6.85-6.55s6.39 3.04 6.39 6.62c0 2.36-1.24 4.44-3.1 5.61-.19.12-.23.38-.08.56 1.38 1.62 2.36 4.11 3.94 6.36 1.64 2.34 3.2 3.47 6.16 3.47h3.75c3.59 0 5.84-3.71 4.04-6.68Z"
            ></path>
          </g>
        </svg>
        <div className="candidate-analysis-header">
          Candidate Analysis Report - {data.name}
        </div>
      </div>
      <div className="analysis-dashboard-page">
        <div className="analysis-dashboard-lhs">
          <LHSB
            username={data.name}
            src={data.cvImgSrc}
            data={data}
            Home="Back"
            id={id}
            preview={data.pdfUrl}
          />
        </div>
        {data.currentOpenings.length === 0 ? (
          <h3>No Analysis Record</h3>
        ) : (
          <div>
            <RHSContent data={data} />
          </div>
        )}
      </div>
    </>
  );
}

export default AnalysisDashboard;
