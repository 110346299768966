import React, { useEffect, useState, ChangeEvent } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogContent,
  DialogActions,
  Field,
  Button,
  Spinner,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
} from "@fluentui/react-components";
import {
  bundleIcon,
  Dismiss24Regular,
  PersonRegular,
  PersonFilled,
  SignOutRegular,
  SignOutFilled,
} from "@fluentui/react-icons";
import { msalConfig } from "../auth";
// import "./styles.css"; // Import the new CSS file

const Topbar: React.FC = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [uploadErrorValidationMsg, setUploadErrorValidationMsg] =
    useState<any>(null);
  const [uploadSucces, setUploadSuccess] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState<any>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const buttonId = useId("callout-button");
  const { pathname } = useLocation();
  const logoutRedirectUri = msalConfig.auth.postLogoutRedirectUri;
  const SignIn = bundleIcon(PersonFilled, PersonRegular);
  const SignOut = bundleIcon(SignOutFilled, SignOutRegular);
  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  function signInClickHandler(instance: any) {
    instance.loginRedirect();
  }

  function signOutClickHandler(instance: any) {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: `${logoutRedirectUri}`,
    };
    instance.logoutRedirect(logoutRequest);
  }

  function SignInButton() {
    const { instance } = useMsal();
    return (
      <Button onClick={() => signInClickHandler(instance)} icon={<SignIn />}>
        Sign In
      </Button>
    );
  }

  const resetForm = () => {
    setSelectedFiles([]);
    setIsUploadInProgress(false);
    setUploadErrorValidationMsg(null);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files);
      setSelectedFiles(filesArray);
    }
  };

  const handleUploadClick = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    console.log("selected files", selectedFiles);
    try {
      setIsUploadInProgress(true);
      const response = await axios.post(`/candidates/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.status === 200 || response.status === 202) {
        setUploadSuccess(true);
        setIsUploadInProgress(false);
        resetForm();
        setUploadErrorValidationMsg(null);
      } else {
        const error = response.data.message || `HTTP Error ${response.status}`;
        console.error(error);
        setIsUploadInProgress(false);
        resetForm();
        setUploadErrorValidationMsg(error);
        return Promise.reject(error);
      }
    } catch (error) {
      console.error(error);
      setIsUploadInProgress(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  return (
    <div className="top-navbar">
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
        className="navbar-stack"
      >
        <Link to="/">
          <div className="logo-container">
            <img src="JardinesLogoWithName.svg" alt="App-logo" />
          </div>
        </Link>
        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          className="nav-link-items"
        >
          <Link
            to="candidates"
            className={pathname === "/candidates" ? "active" : ""}
          >
            Candidates
          </Link>
          <Link
            to="/jobopenings"
            className={pathname === "/jobopenings" ? "active" : ""}
          >
            Job openings
          </Link>
          <div>
            <Button appearance="primary" onClick={() => setIsOpen(!isOpen)}>
              Upload CV
            </Button>
            <Drawer
              type="overlay"
              separator
              open={isOpen}
              onOpenChange={(_, { open }) => setIsOpen(open)}
              position="end"
              size="medium"
            >
              <DrawerHeader>
                <DrawerHeaderTitle
                  action={
                    <Button
                      appearance="subtle"
                      aria-label="Close"
                      icon={<Dismiss24Regular />}
                      onClick={() => setIsOpen(false)}
                    />
                  }
                >
                  Upload Candidate CV
                </DrawerHeaderTitle>
              </DrawerHeader>
              <DrawerBody>
                <div className="upload-container">
                  <div>
                    <Field
                      label=""
                      validationMessage={uploadErrorValidationMsg}
                    >
                      <div>
                        <input
                          multiple
                          id="fileInput"
                          type="file"
                          name="files"
                          onChange={handleFileChange}
                          accept=".pdf,.docx,.doc"
                        />
                        <ul>
                          {selectedFiles.map((file, i) => (
                            <li key={i}>{file.name}</li>
                          ))}
                        </ul>
                      </div>
                      <Button appearance="primary" onClick={handleUploadClick}>
                        Submit
                      </Button>
                      <br />
                      <div>
                        {selectedFiles &&
                          `Document Name: ${selectedFiles.map(
                            (item) => item.name
                          )}`}
                      </div>
                    </Field>
                    <br />
                    {!isUploadInProgress ? null : (
                      <Spinner
                        appearance="primary"
                        labelPosition="after"
                        label="Uploading new document..."
                      />
                    )}
                    {uploadSucces && (
                      <Dialog open={uploadSucces}>
                        <DialogSurface>
                          <DialogBody>
                            <DialogContent>
                              Confirmation of successful CV upload will be sent
                              via Email shortly. Please wait for the analysis
                              result.
                            </DialogContent>
                            <DialogActions>
                              <Button
                                appearance="primary"
                                onClick={() => {
                                  setUploadSuccess(false);
                                  setIsOpen(!isOpen);
                                }}
                              >
                                Got it
                              </Button>
                            </DialogActions>
                          </DialogBody>
                        </DialogSurface>
                      </Dialog>
                    )}
                  </div>
                </div>
              </DrawerBody>
            </Drawer>
          </div>
        </Stack>
        <Stack
          className="persona"
          horizontal
          tokens={{ childrenGap: 20 }}
          id={buttonId}
          onClick={toggleIsCalloutVisible}
        >
          {activeAccount?.name ? (
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Persona text={activeAccount.name} size={PersonaSize.size40} />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    onClick={() => signOutClickHandler(instance)}
                    icon={<SignOut />}
                  >
                    Sign Out
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          ) : (
            <SignInButton />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default Topbar;
