import { IDropdownOption } from "@fluentui/react/lib/Dropdown";


export const yearOfExperienceOptions: IDropdownOption[] = [
	{ key: "0-2", text: "0-2 years" },
	{ key: "3-5", text: "3-5 years" },
	{ key: "6-9", text: "6-9 years" },
	{ key: "10+", text: "Above 10 years" },
	{ key: "0", text: "" },
];

export const sourceOptions: IDropdownOption[] = [
	{ key: "email", text: "Email" },
	{ key: "jobdb", text: "Jobs DB" },
	{ key: "linkedin", text: "LinkedIn" },
	{ key: "", text: "" },
];

export const statusOptions: IDropdownOption[] = [
	{ key: "active", text: "Active" },
	{ key: "inactive", text: "Inactive" },
	{ key: "", text: "" },
];