import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  makeStyles,
  mergeClasses,
  tokens,
  shorthands,
  Avatar,
  Caption1,
  Title1,
  Button,
  Body1,
  Divider,
  Badge,
} from "@fluentui/react-components";
import {
  ArrowUpRightFilled,
  ArrowCircleRightRegular,
  DismissCircle16Regular,
  Person24Regular,
  Briefcase24Regular,
  DocumentCheckmark24Regular,
  ClipboardDataBar24Regular,
} from "@fluentui/react-icons";
import { Card, CardHeader } from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import "../../styles/Home.css";

type OverallStatsModel = {
  total_job_posts: number;
  this_week_job_posts: number;
  job_posts_diff: number;
  total_cv: number;
  this_week_cv: number;
  cv_diff: number;
  total_analysis: number;
  this_week_analysis: number;
  analysis_diff: number;
};

const useStyles = makeStyles({
  main: {
    ...shorthands.gap("36px"),
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  card: {
    minHeight: "185px",
    minWidth: "300px",
    height: "fit-content",
  },
  section: {
    width: "fit-content",
  },
  title: {
    ...shorthands.margin(0, 0, "12px"),
  },
  horizontalCardImage: {
    width: "64px",
    height: "64px",
  },
  headerImage: {
    ...shorthands.borderRadius("4px"),
    maxWidth: "44px",
    maxHeight: "44px",
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  text: {
    ...shorthands.margin(0),
  },
  divider: {
    flexGrow: 0,
  },
  flex: {
    ...shorthands.gap("4px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#7C8DB5",
    fontWeight: 600,
    columnGap: "8px",
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
  },
  footer: {
    ...shorthands.gap("12px"),
  },
});

export default function Home() {
  const styles = useStyles();
  const navigate = useNavigate();
  const [overallStats, setOverallStats] = useState<
    OverallStatsModel | undefined
  >();
  const [diffStats, setDiffStats] = useState<any>(overallStats);
  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const { isLoading, error, refetch } = useQuery(
    ["overall-statistics"],
    () => axios.get("/overall-stat").then((response) => response.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const percentageFormat = (num: number) => {
          return Math.round(num * 100).toFixed(1) + "%";
        };
        setOverallStats(data);
        setDiffStats({
          job_posts_diff: {
            percentage: percentageFormat(data.job_posts_diff),
            actual_number: data.this_week_job_posts,
          },
          cv_diff: {
            percentage: percentageFormat(data.cv_diff),
            actual_number: data.this_week_cv,
          },
          analysis_diff: {
            percentage: percentageFormat(data.analysis_diff),
            actual_number: data.this_week_analysis,
          },
        });
      },
    }
  );

  return (
    <>
      {overallStats && diffStats ? (
        <div>
          <div className="image-container">
            <div className="image-wrapper">
              <img
                src="JardinesLanding.jpg"
                alt="App-Landing"
                className="image-style"
              />
            </div>
          </div>
          <div className="landing-page-cards-container">
            <Card className={styles.card}>
              <CardHeader
                className="card-header-style"
                header={<Title1>Welcome</Title1>}
                description={
                  <Body1 className={styles.caption}>Let's Get Started!</Body1>
                }
                action={
                  <Avatar
                    color="royal-blue"
                    icon={<Person24Regular />}
                    activeAppearance="ring-shadow"
                    aria-label="Job"
                  />
                }
              />
              <Divider className={styles.divider} />
              <footer className={mergeClasses(styles.flex, styles.footer)}>
                <div className="card-footer">
                  <Button
                    appearance="subtle"
                    icon={<ArrowCircleRightRegular />}
                    iconPosition="after"
                    className={styles.button}
                    onClick={() => {
                      navigate("/candidates");
                    }}
                  >
                    Browse Existing Candidates
                  </Button>
                  <Button
                    appearance="subtle"
                    icon={<ArrowCircleRightRegular />}
                    iconPosition="after"
                    className={styles.button}
                    onClick={() => {
                      navigate("/jobopenings");
                    }}
                  >
                    Browse Current Job Openings
                  </Button>
                </div>
              </footer>
            </Card>
            <Card className={styles.card}>
              <CardHeader
                header={<Title1>{overallStats.total_job_posts}</Title1>}
                description={
                  <Body1 className={styles.caption}>
                    Total No. of Job Posts
                  </Body1>
                }
                action={
                  <Avatar
                    color="royal-blue"
                    icon={<Briefcase24Regular />}
                    activeAppearance="ring-shadow"
                    aria-label="Job"
                  />
                }
              />
              <Divider className={styles.divider} />
              <footer className={mergeClasses(styles.flex, styles.footer)}>
                <div className={styles.flex}>
                  {overallStats.job_posts_diff > 0 ? (
                    <>
                      <ArrowUpRightFilled primaryFill="#5DC983" />
                      <Caption1>
                        {diffStats.job_posts_diff.actual_number}
                      </Caption1>
                      <Badge appearance="filled" color="brand">
                        +{diffStats.job_posts_diff.percentage} this week
                      </Badge>
                    </>
                  ) : (
                    <>
                      <DismissCircle16Regular />
                      <Badge appearance="filled" color="informative">
                        No new Job Posts this week
                      </Badge>
                    </>
                  )}
                </div>
              </footer>
            </Card>
            <Card className={styles.card}>
              <CardHeader
                header={<Title1>{overallStats.total_cv}</Title1>}
                description={
                  <Body1 className={styles.caption}>
                    Total No. of CVs Received
                  </Body1>
                }
                action={
                  <Avatar
                    color="royal-blue"
                    icon={<DocumentCheckmark24Regular />}
                    activeAppearance="ring-shadow"
                    aria-label="Job"
                  />
                }
              />
              <Divider className={styles.divider} />
              <footer className={mergeClasses(styles.flex, styles.footer)}>
                <div className={styles.flex}>
                  {overallStats.cv_diff > 0 ? (
                    <>
                      <ArrowUpRightFilled primaryFill="#5DC983" />
                      <Caption1>{diffStats.cv_diff.actual_number}</Caption1>
                      <Badge appearance="filled" color="brand">
                        +{diffStats.cv_diff.percentage} this week
                      </Badge>
                    </>
                  ) : (
                    <>
                      <DismissCircle16Regular />
                      <Badge appearance="filled" color="informative">
                        No new CVs this week
                      </Badge>
                    </>
                  )}
                </div>
              </footer>
            </Card>
            <Card className={styles.card}>
              <CardHeader
                header={<Title1>{overallStats.total_analysis}</Title1>}
                description={
                  <Body1 className={styles.caption}>
                    Times of Analysis Report Generated
                  </Body1>
                }
                action={
                  <Avatar
                    color="royal-blue"
                    icon={<ClipboardDataBar24Regular />}
                    activeAppearance="ring-shadow"
                    aria-label="Job"
                  />
                }
              />
              <Divider className={styles.divider} />
              <footer className={mergeClasses(styles.flex, styles.footer)}>
                <div className={styles.flex}>
                  {overallStats.analysis_diff > 0 ? (
                    <>
                      <ArrowUpRightFilled primaryFill="#5DC983" />
                      <Caption1>
                        {diffStats.analysis_diff.actual_number}
                      </Caption1>
                      <Badge appearance="filled" color="brand">
                        +{diffStats.analysis_diff.percentage} this week
                      </Badge>
                    </>
                  ) : (
                    <>
                      <DismissCircle16Regular />
                      <Badge appearance="filled" color="informative">
                        No new Analysis this week
                      </Badge>
                    </>
                  )}
                </div>
              </footer>
            </Card>
          </div>
        </div>
      ) : (
        <>
          <div className="image-container">
            <div className="image-wrapper">
              <img
                src="JardinesLanding.jpg"
                alt="App-Landing"
                className="image-style"
              />
            </div>
          </div>
          <div className="landing-page-cards-container">
            <Card className={styles.card}>
              <CardHeader
                className="card-header-style"
                header={<Title1>Welcome</Title1>}
                description={
                  <Body1 className={styles.caption}>Let's Get Started!</Body1>
                }
                action={
                  <Avatar
                    color="royal-blue"
                    icon={<Person24Regular />}
                    activeAppearance="ring-shadow"
                    aria-label="Job"
                  />
                }
              />
              <Divider className={styles.divider} />
              <footer className={mergeClasses(styles.flex, styles.footer)}>
                <div className="card-footer">
                  <Button
                    appearance="subtle"
                    icon={<ArrowCircleRightRegular />}
                    iconPosition="after"
                    className={styles.button}
                    onClick={() => {
                      navigate("/candidates");
                    }}
                  >
                    Browse Existing Candidates
                  </Button>
                  <Button
                    appearance="subtle"
                    icon={<ArrowCircleRightRegular />}
                    iconPosition="after"
                    className={styles.button}
                    onClick={() => {
                      navigate("/jobopenings");
                    }}
                  >
                    Browse Current Job Openings
                  </Button>
                </div>
              </footer>
            </Card>
          </div>
        </>
      )}
    </>
  );
}
