import { Text, List, FocusZone, FocusZoneDirection } from "@fluentui/react";
import React, { useState } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import DetailPage from "./DetailPage";
import { Dropdown } from "@fluentui/react/lib/Dropdown";

import "../../styles/Candidates.css";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { Candidate, searchFields } from "../../models/Candidate";
import {
  yearOfExperienceOptions,
  sourceOptions,
  statusOptions,
} from "../../constants/dropdownOptions";

const verticalGapStackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const buttonStyles = {
  root: {
    backgroundColor: "rgb(91, 95, 199)",
    color: "white",
    borderColor: "rgb(91, 95, 199)",
    borderRadius: 4,
  },
};

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;
const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: "flex",
      selectors: {
        "&:hover": { background: palette.neutralLight },
      },
      gap: 10,
    },
  ],
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: "hidden",
    flexGrow: 1,
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: "nowrap",
      color: "#4e4e9b",
      textOverflow: "ellipsis",
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: "center",
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
  itemCategory: {
    fontWeight: "bold",
    color: "#858585",
  },
});

const stackStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
};

const rowStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
};

const cellStyles = {
  root: {
    width: "25%",
    padding: "8px",
  },
};

const descriptionStyles = {
  root: {
    flex: 1,
    padding: "8px",
  },
};

export default function Candidates() {
  const [fields, setFields] = useState<searchFields>({});
  const [display, setDisplay] = useState({
    searchIcon: true,
    detailBox: false,
  });
  const [searchCandidates, setSearchCandidates] = useState(false);
  const [selectedSkillIndex, setSelectedSkillIndex] = useState<
    number | undefined
  >(undefined);

  const [candidatesData, setCandidatesData] = useState([]);
  const [selectedCandidateID, setSelectedCandidateID] = useState<
    number | undefined
  >(undefined);

  const {
    isLoading,
    error,
    data: retrieveData,
  } = useQuery(
    ["all-candidates"],
    () =>
      axios
        .post(`/candidates/search`, fields)
        .then((response) => response.data),
    {
      refetchOnWindowFocus: false,
      enabled: searchCandidates,
      onSuccess: (data) => {
        setCandidatesData(data);
      },
    }
  );

  const formattedDate = (data: string) => {
    const formattedDate = new Date(data).toLocaleDateString(
      "en-HK",

      {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timeZone: "UTC",
      }
    );

    return formattedDate;
  };
  const handleReadMoreClick = (
    e: any,
    item: Candidate,
    itemIndex: number | undefined
  ) => {
    e.preventDefault();
    const expandCandidates: any = [...retrieveData];
    expandCandidates[itemIndex!].isSkillExpanded =
      !expandCandidates[itemIndex!].isSkillExpanded;
    console.log("expanded?");
    console.log(item.isSkillExpanded);
    console.log(expandCandidates);

    setSelectedSkillIndex(itemIndex);
    setCandidatesData(expandCandidates);
  };

  const onCandidateNameClick = (
    id: number | undefined,
    index: number | undefined
  ) => {
    setDisplay((prev) => ({ ...prev, detailBox: true }));
    setSelectedCandidateID(id);
  };

  const onRenderCell = (
    item: Candidate | undefined,
    itemIndex: number | undefined
  ): JSX.Element => {
    if (item) {
      return (
        <div
          className={classNames.itemCell}
          data-is-focusable={true}
          key={item.id}
        >
          <div className={classNames.itemContent}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.preventDefault();
                  onCandidateNameClick(item.id, itemIndex);
                }}
              >
                <div className={classNames.itemName}>{item.name}</div>{" "}
                <div className={classNames.itemIndex}>
                  {`Last modified: ${formattedDate(item.lastModified)}`}
                </div>
              </div>
            </div>

            <Stack
              horizontal
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "8px 0",
              }}
            >
              <Text
                variant="medium"
                nowrap
                block
                style={{ color: "#7676d2", fontWeight: "bold" }}
              >
                {item.currentProfile}
              </Text>
            </Stack>
            <Stack horizontalAlign="start" styles={stackStyles}>
              {/* Header Row */}
              <Stack horizontal styles={rowStyles}>
                <Stack.Item styles={cellStyles}>
                  <Text
                    className={classNames.itemCategory}
                    variant="medium"
                    nowrap
                    block
                  >
                    Experience
                  </Text>
                </Stack.Item>
                <Stack.Item styles={descriptionStyles}>
                  <Text variant="medium" block>
                    {item.workExperience[0] ? (
                      item.workExperience.slice(0, 3).map((item) => (
                        <div>
                          {item.title} at {item.company}, {item.yearRange}
                        </div>
                      ))
                    ) : (
                      <Text style={{ fontStyle: "italic" }}>No Record</Text>
                    )}
                  </Text>
                </Stack.Item>
              </Stack>

              <Stack horizontal styles={rowStyles}>
                <Stack.Item styles={cellStyles}>
                  <Text
                    className={classNames.itemCategory}
                    variant="medium"
                    nowrap
                    block
                  >
                    Skills
                  </Text>
                </Stack.Item>
                <Stack.Item styles={descriptionStyles} key={itemIndex}>
                  {item.isSkillExpanded ||
                  (item.skills && item.skills.length < 300) ? (
                    <>
                      <Text variant="medium" block>
                        {item.skills}
                      </Text>
                    </>
                  ) : item.skills && item.skills.length > 300 ? (
                    <>
                      <Text
                        style={{
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          display: "-webkit-box",
                        }}
                        variant="medium"
                        block
                      >
                        {item.skills}
                      </Text>
                      <div
                        style={{ color: "#0078D4", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleReadMoreClick(e, item, itemIndex);
                        }}
                      >
                        read more...
                      </div>
                    </>
                  ) : (
                    <Text style={{ fontStyle: "italic" }}>No Record</Text>
                  )}
                </Stack.Item>
              </Stack>

              <Stack horizontal styles={rowStyles}>
                <Stack.Item styles={cellStyles}>
                  <Text
                    className={classNames.itemCategory}
                    variant="medium"
                    nowrap
                    block
                  >
                    Education
                  </Text>
                </Stack.Item>
                <Stack.Item styles={descriptionStyles}>
                  <Text variant="medium" block>
                    {item.education[0] ? (
                      item.education.map((item, index) => {
                        if (!item.degree) {
                          return (
                            <div>
                              {item.college}, {item.yearRange}
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              {item.degree} at {item.college}, {item.yearRange}
                            </div>
                          );
                        }
                      })
                    ) : (
                      <Text style={{ fontStyle: "italic" }}>No Record</Text>
                    )}
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack>
          </div>
        </div>
      );
    } else {
      return <div className="candidate-content">No candidates returned</div>;
    }
  };

  function triggerSearch() {
    setDisplay((prev) => ({ ...prev, detailBox: false }));
    setSearchCandidates(false);
    setTimeout(() => setSearchCandidates(true), 0);
  }

  return (
    <div className="flex-center">
      <div className="search-panel">
        <div className="search-panel-content">
          <Text variant="xxLarge">Candidates</Text>
          <Stack enableScopedSelectors tokens={verticalGapStackTokens}>
            <TextField
              label="Candidate Name"
              onChange={(ev, newValue) => {
                setFields((prev) => ({ ...prev, name: newValue }));
              }}
            />

            <TextField
              label="Job Title"
              onChange={(ev, newValue) => {
                setFields((prev) => ({ ...prev, title: newValue }));
              }}
            />

            <TextField
              label="Skills"
              onChange={(ev, newValue) => {
                setFields((prev) => ({ ...prev, skill: newValue }));
              }}
            />

            <TextField
              label="Current/Previous Company"
              onChange={(ev, newValue) => {
                setFields((prev) => ({ ...prev, company: newValue }));
              }}
            />

            <Dropdown
              defaultSelectedKey="0"
              label="Years of Work Experience"
              options={yearOfExperienceOptions}
              required={false}
              onChange={(ev, item) => {
                if (item) {
                  setFields((prev) => ({
                    ...prev,
                    yearOfExperience: item.key,
                  }));
                }
              }}
            />

            <TextField
              label="School"
              onChange={(ev, newValue) => {
                setFields((prev) => ({ ...prev, school: newValue }));
              }}
            />

            <Dropdown
              defaultSelectedKey=""
              label="Source"
              options={sourceOptions}
              required={false}
              onChange={(ev, item) => {
                if (item) {
                  setFields((prev) => ({ ...prev, source: item.text }));
                }
              }}
            />
            <Dropdown
              defaultSelectedKey=""
              label="Status"
              options={statusOptions}
              required={false}
              onChange={(ev, item) => {
                if (item) {
                  setFields((prev) => ({ ...prev, status: item.text }));
                }
              }}
            />
            <span
              style={{
                paddingTop: "18px",
              }}
            >
              <PrimaryButton
                text="Search"
                allowDisabledFocus
                onClick={() => {
                  triggerSearch();
                  setDisplay((prev) => ({ ...prev, searchIcon: false }));
                }}
                styles={buttonStyles}
              />
            </span>
          </Stack>
        </div>
      </div>
      <div className="main-content">
        {display.searchIcon ? (
          <div className="search-icon-container">
            <img
              src="SearchIcon.svg"
              alt="searchIcon"
              width={700}
              style={{ opacity: "85%" }}
            />
            <div className="search-icon-text">
              <Text block variant="xxLarge">
                Start a Search
              </Text>
              <Text block variant="mediumPlus">
                You haven't started a search yet. Use the Search Filter to start
                finding candidates.
              </Text>
            </div>
          </div>
        ) : (
          <>
            {!display.detailBox ? (
              <div className="result-retrieving">
                {isLoading && (
                  <Text variant="xxLarge">Retrieving Result...</Text>
                )}
                <>
                  {error && (
                    <Text variant="xxLarge">
                      An error occurred. Please contact IT for more information.
                    </Text>
                  )}
                </>
                {!isLoading && candidatesData && (
                  <div className="result-count">
                    <Text variant="mediumPlus">
                      Total Result: {candidatesData.length}
                    </Text>
                  </div>
                )}

                <FocusZone direction={FocusZoneDirection.vertical}>
                  {!isLoading && candidatesData && (
                    <List
                      items={candidatesData}
                      onRenderCell={(item, index) => onRenderCell(item, index)}
                    />
                  )}
                </FocusZone>
              </div>
            ) : (
              <DetailPage
                isDisplay={display.detailBox}
                setIsDisplay={() =>
                  setDisplay((prev) => ({ ...prev, detailBox: false }))
                }
                selectedIntID={selectedCandidateID}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
