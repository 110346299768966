import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useId, useBoolean } from "@fluentui/react-hooks";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Text,
  IIconProps,
  Stack,
  getTheme,
  mergeStyleSets,
  FontWeights,
  ContextualMenu,
  Modal,
  IDragOptions,
} from "@fluentui/react";
import { Caption1, Tooltip } from "@fluentui/react-components";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IconButton } from "@fluentui/react/lib/Button";
import { IJob } from "./View";
import "../../styles/JobOpenings.css";

const EmployeeTable = ({ ...props }) => {
  const {
    tableData,
    onEdit,
    setOpeningIds,
    selectedRows,
    setSelectedRows,
    selectedMatchedRows,
    setSelectedMatchedRows,
    setIsDisabled,
  } = props;
  const queryClient = useQueryClient();
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [sortedModalData, setSortedModalData] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [selectedMatchedIds, setSelectedMatchedIds] = useState<any | null>(
    null
  );
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteDisabled, setIsModalDeleteDisabled] = useState<any>(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(true);
  const [keepInBounds, { toggle: toggleKeepInBounds }] = useBoolean(true);
  const dragOptions = React.useMemo(
    (): IDragOptions => ({
      moveMenuItemText: "Move",
      closeMenuItemText: "Close",
      menu: ContextualMenu,
      keepInBounds,
      dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
    }),
    [keepInBounds]
  );

  useEffect(() => {
    setSortedData([...tableData]);
  }, [tableData]);

  const handleSort = (key: string) => {
    let order = "asc";
    if (sortColumn === key && sortOrder === "asc") {
      order = "desc";
    }
    setSortColumn(key);
    setSortOrder(order);
    const sorted = [...sortedData].sort((a, b) => {
      if (order === "asc") {
        return a[key] < b[key] ? -1 : 1;
      } else {
        return a[key] > b[key] ? -1 : 1;
      }
    });
    setSortedData(sorted);
  };

  const handleModalSort = (key: string) => {
    let order = "asc";
    if (sortColumn === key && sortOrder === "asc") {
      order = "desc";
    }
    setSortColumn(key);
    setSortOrder(order);
    const sorted = [...sortedModalData].sort((a, b) => {
      if (order === "asc") {
        return a[key] < b[key] ? -1 : 1;
      } else {
        return a[key] > b[key] ? -1 : 1;
      }
    });
    setSortedModalData(sorted);
  };

  const handleFilter = (value: string) => {
    const lowerCaseValue = value.toLowerCase();
    const filtered = tableData.filter((item: any) =>
      item.title.toLowerCase().includes(lowerCaseValue)
    );
    setSortedData(filtered);
    setFilter(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    if (selectedMatchedIds.review > 0 && selectedMatchedIds.review[0]._id) {
      setSelectedMatchedRows([]);
    }
  };

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const filteredRecommendation = (reviews: any) => {
    const filteredReviews = reviews.filter(
      (item: any) => item.recommendation !== "Reject"
    );
    return filteredReviews.length;
  };

  const handleMatchedId = (rowId: string, review: any) => {
    const newSelectedRow = sortedData.find((item) => item._id === rowId);
    const filteredData = sortedData.filter(
      (item) => item._id === rowId
    ) as IJob[];
    setSortedModalData(filteredData[0]?.review);
    setSelectedMatchedIds(newSelectedRow);
    setExpandedRowId(rowId);
    setIsModalDeleteDisabled(false);
    if (!review[0]._id) {
      hideModal();
    } else {
      showModal();
    }
  };

  const handleRowSelect = (rowId: string) => {
    const newSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id: string) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(newSelectedRows);
    setOpeningIds(newSelectedRows);
    setIsDisabled(newSelectedRows.length === 0);
  };

  const handleSelectAll = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
      setIsDisabled(true);
    } else {
      const allRowIds = sortedData.map((item) => item._id);
      setSelectedRows(allRowIds);
      setOpeningIds(allRowIds);
      setIsDisabled(false);
    }
  };

  const handleCandidateClick = (id: number) => {
    window.open(`candidates/dashboard/${id}`, "_blank");
  };

  const deleteMatchedCandidate = useMutation({
    mutationFn: (id: any) => {
      return axios.delete(`/openings/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data, vars, ctx) => {
      queryClient.invalidateQueries({ queryKey: ["all-openings"] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const onModalDelete = () => {
    selectedMatchedRows.forEach((id: any) => {
      deleteMatchedCandidate.mutate(id);
      setSelectedRows(selectedRows.filter((id: string) => id !== id));
    });
    setIsDisabled(true);
    hideDeleteModal();
  };

  return (
    <div className="employee-table-container">
      <input
        type="text"
        placeholder="Search by Job Title"
        value={filter}
        onChange={(e) => handleFilter(e.target.value)}
        className="filter-input"
      />
      <table className="job-table">
        <thead>
          <tr>
            <th
              onClick={(e) => {
                if ((e.target as HTMLInputElement).type !== "checkbox") {
                  handleSort("title");
                }
              }}
              className="table-title-main"
            >
              <input
                type="checkbox"
                id="checkbox-icon"
                checked={selectedRows.length === sortedData.length}
                onChange={() => handleSelectAll()}
              />
              <span>
                Title
                {sortColumn === "title"
                  ? sortOrder === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </span>
            </th>
            <th
              onClick={() => handleSort("review")}
              className="table-title-main"
            >
              Review matched candidate{" "}
              {sortColumn === "review" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </th>
            <th
              onClick={() => handleSort("marketSalary")}
              className="table-title-main"
            >
              Market Salary{""}
              {sortColumn === "marketSalary"
                ? sortOrder === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th
              onClick={() => handleSort("minInternalSalary")}
              className="table-title-main"
            >
              Min Internal Salary{""}
              {sortColumn === "minInternalSalary"
                ? sortOrder === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th
              onClick={() => handleSort("maxInternalSalary")}
              className="table-title-main"
            >
              Max Internal Salary{""}
              {sortColumn === "maxInternalSalary"
                ? sortOrder === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th
              onClick={() => handleSort("postDate")}
              className="table-title-main"
            >
              Post Date{""}
              {sortColumn === "postDate"
                ? sortOrder === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th
              onClick={() => handleSort("status")}
              className="table-title-main"
            >
              Status{""}
              {sortColumn === "status" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.length < 1
            ? "No data found"
            : sortedData.map((item, index: any) =>
                item.status === "In Progress" ? (
                  <>
                    <tr
                      key={item._id}
                      className={
                        selectedRows.includes(item._id) ? "selected-row" : ""
                      }
                    >
                      <td>
                        <input
                          type="checkbox"
                          disabled
                          checked={selectedRows.includes(item._id)}
                          onChange={() => handleRowSelect(item._id)}
                        />
                        <Tooltip
                          withArrow
                          content={{
                            children: "Please wait until scanning is complete",
                          }}
                          relationship="label"
                        >
                          <span>{item.title}</span>
                        </Tooltip>
                      </td>
                      <td>Scanning In Progress</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{item.postDate}</td>
                      <td>{item.status}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr
                      key={item._id}
                      className={
                        selectedRows.includes(item._id)
                          ? "selected-row"
                          : item.status === "Under Review"
                          ? "under-review-cell"
                          : "reviewed-cell"
                      }
                    >
                      <td
                        className={
                          item.status === "Under Review" ? "" : "table-title"
                        }
                      >
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(item._id)}
                          onChange={() => handleRowSelect(item._id)}
                        />
                        <Tooltip
                          withArrow
                          content="Click to edit the job opening"
                          relationship="label"
                        >
                          <span
                            className="job-title"
                            onClick={() => onEdit(item._id)}
                          >
                            {item.title}
                          </span>
                        </Tooltip>
                      </td>
                      <td
                        onClick={() => handleMatchedId(item._id, item.review)}
                      >
                        {!item.review[0]._id ||
                        filteredRecommendation(item.review) === 0 ? (
                          "There are currently no matched candidates"
                        ) : (
                          <Tooltip
                            withArrow
                            content="Click to see matched candidates"
                            relationship="label"
                          >
                            <span className="matched-candidate">
                              Click to see a total of{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {filteredRecommendation(item.review)}
                              </span>{" "}
                              matched candidates
                            </span>
                          </Tooltip>
                        )}
                      </td>
                      <td>
                        {item.marketSalary ? (
                          new Intl.NumberFormat("en-HK", {
                            style: "currency",
                            currency: "HKD",
                            currencyDisplay: "symbol",
                          })
                            .format(item.marketSalary)
                            .replace("HK", "HKD ")
                        ) : (
                          <Caption1>
                            <i>Not Provided</i>
                          </Caption1>
                        )}
                      </td>
                      <td>
                        {item.minInternalSalary ? (
                          new Intl.NumberFormat("en-HK", {
                            style: "currency",
                            currency: "HKD",
                            currencyDisplay: "symbol",
                          })
                            .format(item.minInternalSalary)
                            .replace("HK", "HKD ")
                        ) : (
                          <Caption1>
                            <i>Not Provided</i>
                          </Caption1>
                        )}
                      </td>
                      <td>
                        {item.maxInternalSalary ? (
                          new Intl.NumberFormat("en-HK", {
                            style: "currency",
                            currency: "HKD",
                            currencyDisplay: "symbol",
                          })
                            .format(item.maxInternalSalary)
                            .replace("HK", "HKD ")
                        ) : (
                          <Caption1>
                            <i>Not Provided</i>
                          </Caption1>
                        )}
                      </td>
                      <td>{item.postDate}</td>
                      <td>{item.status}</td>
                    </tr>
                    {expandedRowId === item._id && (
                      <>
                        <Modal
                          titleAriaId={item._id}
                          isOpen={isModalOpen}
                          onDismiss={hideModal}
                          isBlocking={true}
                          dragOptions={dragOptions}
                        >
                          <>
                            <div className={contentStyles.header}>
                              <h2 className={contentStyles.heading}>
                                {item.title}
                              </h2>
                              <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={hideModal}
                              />
                            </div>
                            <div className={contentStyles.body}>
                              <table
                                className="job-table"
                                style={{
                                  maxWidth: 600,
                                  width: "100%",
                                  margin: "auto",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      onClick={(e) => {
                                        if (
                                          (e.target as HTMLInputElement)
                                            .type !== "checkbox"
                                        ) {
                                          handleModalSort("name");
                                        }
                                      }}
                                      className="table-title-main"
                                    >
                                      Name
                                      {sortColumn === "name"
                                        ? sortOrder === "asc"
                                          ? "↑"
                                          : "↓"
                                        : ""}
                                    </th>
                                    <th
                                      onClick={() =>
                                        handleModalSort("recommendation")
                                      }
                                    >
                                      Recommendations
                                      {sortColumn === "recommendation"
                                        ? sortOrder === "asc"
                                          ? "↑"
                                          : "↓"
                                        : ""}
                                    </th>
                                    <th
                                      onClick={() => handleModalSort("score")}
                                    >
                                      Scores
                                      {sortColumn === "score"
                                        ? sortOrder === "asc"
                                          ? "↑"
                                          : "↓"
                                        : ""}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sortedModalData.map(
                                    (v: any) =>
                                      v.recommendation !== "Reject" && (
                                        <tr
                                          key={v._id}
                                          className={
                                            selectedRows.includes(v._id)
                                              ? "selected-row"
                                              : "matched-candidate-cell"
                                          }
                                        >
                                          <td className="table-title">
                                            <span
                                              className="matched-candidate"
                                              onClick={() =>
                                                handleCandidateClick(
                                                  v.candidateId
                                                )
                                              }
                                            >
                                              {v.name}
                                            </span>
                                          </td>
                                          <td>{v.recommendation}</td>
                                          <td>{v.score}</td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </Modal>
                        <Modal
                          isOpen={isDeleteModalOpen}
                          onDismiss={hideDeleteModal}
                          isBlocking={false}
                          containerClassName="modal-container"
                          scrollableContentClassName="modal-scroll-content"
                        >
                          <Stack
                            tokens={{ childrenGap: 10 }}
                            style={{
                              width: 600,
                              padding: 16,
                              textAlign: "center",
                              alignItems: "center",
                              marginTop: 8,
                            }}
                          >
                            <Text variant="xLarge">
                              Confirm to delete Job Openings
                            </Text>
                            <Text variant="mediumPlus">
                              The delete action cannot be reverted. Are you
                              confirm to delete the Job Openings?
                            </Text>
                            <Stack
                              horizontal
                              tokens={{ childrenGap: 10 }}
                              style={{
                                justifyContent: "center",
                                marginTop: 14,
                              }}
                            >
                              <PrimaryButton onClick={hideDeleteModal}>
                                Cancel
                              </PrimaryButton>
                              <PrimaryButton
                                onClick={onModalDelete}
                                style={{
                                  backgroundColor: "red",
                                  border: "none",
                                }}
                              >
                                Delete
                              </PrimaryButton>
                            </Stack>
                          </Stack>
                        </Modal>
                      </>
                    )}
                  </>
                )
              )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;

const cancelIcon: IIconProps = { iconName: "Cancel" };
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 24px",
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: "inherit",
    margin: "0",
  },
  body: {
    flex: "4 4 auto",
    padding: "24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
