import React, { useState } from "react";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import {
  Avatar,
  Body1,
  Button,
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Divider,
  Field,
  Subtitle1,
  Textarea,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  bundleIcon,
  DeleteRegular,
  DeleteFilled,
  EditRegular,
  EditFilled,
  SendRegular,
  SendFilled,
  CommentRegular,
  DismissRegular,
} from "@fluentui/react-icons";

interface Comment {
  Comment: string;
  CommentId: number;
  EditDatetime: string;
  CandidateId: number;
  Commenter: string;
  CommentDatetime: string;
}

interface INewComment {
  body: string;
  comment: string;
  commenter: string;
}

export const CandidatesComment = ({ ...props }) => {
  const { selectedIntID } = props;
  const queryClient = useQueryClient();
  const Edit = bundleIcon(EditFilled, EditRegular);
  const Delete = bundleIcon(DeleteFilled, DeleteRegular);
  const Send = bundleIcon(SendFilled, SendRegular);

  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [newInput, setNewInput] = useState("");
  const { instance } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const [newCommentRequests, setNewCommentRequests] = useState<INewComment>({
    body: "",
    comment: "",
    commenter: `${activeAccount?.name}`,
  });
  const [obsoleteCommentID, setObsoleteCommentID] = useState<any>(null);
  const [obsoleteComment, setObsoleteComment] = useState<any>("");

  const [isEditable, setIsEditable] = useState(false);
  const [editedComment, setEditedComment] = useState("");
  const [editedCommentId, setEditedCommentId] = useState<number | null>(null);
  const [editCommentRequests, setEditCommentRequests] = useState<INewComment>({
    body: "",
    comment: "",
    commenter: `${activeAccount?.name}`,
  });

  const { isLoading, error, data } = useQuery(
    ["comments"],
    () =>
      axios
        .get(`/candidates/${selectedIntID}/comments`)
        .then((response) => response.data),
    {
      enabled: selectedIntID !== null,
      onSuccess: (data) => {
        setCommentList(data.content);
      },
    }
  );
  const handleCommentInput = (value: string, condition: string) => {
    if (condition === "add") {
      setNewInput(value);
      setNewCommentRequests({ ...newCommentRequests, ["comment"]: value });
    }
    if (condition === "edit") {
      setEditedComment(value);
      setEditCommentRequests({ ...editCommentRequests, ["comment"]: value });
    }
  };

  const addNewComment = useMutation({
    mutationFn: (newComment: INewComment) => {
      return axios
        .post(`/candidates/${selectedIntID}/comments`, newComment, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.data);
    },
    onSuccess: (data: INewComment) => {
      console.log("add comment useMutation", data);
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleEditComment = (e: any, id: number, comment: string) => {
    setIsEditable(true);
    setEditedCommentId(id);
    setEditedComment(comment);
  };

  const editComment = useMutation({
    mutationFn: (editedComment: INewComment) => {
      return axios
        .put(
          `/candidates/${selectedIntID}/comments/${editedCommentId}`,
          editedComment,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => response.data);
    },
    onSuccess: (data: INewComment) => {
      console.log("edit comment useMutation", data);

      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleDeleteComment = (id: number) => {
    setObsoleteCommentID(id);
    const filtered = commentList.filter((comment) => comment.CommentId === id);
    setObsoleteComment(filtered[0].Comment);
    console.log("obsolete data filter", obsoleteComment);
  };

  const deleteComment = useMutation({
    mutationFn: () => {
      return axios
        .delete(`/candidates/${selectedIntID}/comments/${obsoleteCommentID}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.data);
    },
    onSuccess: (data: INewComment) => {
      console.log("add comment useMutation", data);
      queryClient.invalidateQueries({ queryKey: ["comments"] });
      setObsoleteCommentID(null);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const formattedDate = (data: string) => {
    const formattedDate = new Date(data).toLocaleDateString(
      "en-HK",

      {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }
    );

    return formattedDate;
  };

  const onSubmitComment = async (condition: string) => {
    if (condition === "add") {
      if (!newInput || newInput.trim() === "") {
        return;
      } else {
        addNewComment.mutate(newCommentRequests);
        setNewInput("");
      }
    } else if (condition === "edit") {
      if (!editedComment || editedComment.trim() === "") {
        return;
      } else {
        editComment.mutate(editCommentRequests);
        setIsEditable(false);
      }
    }
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.shiftKey) return;

    if (e.key === "Enter") {
      e.preventDefault();
      console.log("test onkeydown");
    }
  };

  return (
    <>
      <div className="candidates-comment-container">
        <div className="comment-header">
          <Subtitle1>Comment(s)</Subtitle1>
          <Button appearance="transparent" icon={<CommentRegular />}>
            {commentList.length}
          </Button>
        </div>

        <Divider className="comment-divider" />
        <div className="comment-input-container">
          <div className="avatar-container">
            <Avatar />
          </div>
          <div className="comment-input">
            <Field>
              <Textarea
                disabled={false}
                placeholder="Leave a Comment"
                className="comment-input"
                value={newInput}
                onKeyDown={onKeyDown}
                onChange={(e) => handleCommentInput(e.target.value, "add")}
              />
            </Field>
            <div className="text-input-toolbar">
              <div
                className="text-input-toolbar"
                style={{ display: "flex", flexGrow: 1 }}
              ></div>
              <Button
                appearance="transparent"
                icon={<Send />}
                onClick={() => onSubmitComment("add")}
              />
            </div>
          </div>
        </div>

        <div className="comment-list">
          {commentList.map((item, index) => (
            <div>
              <Card key={index} className="comment-card">
                <CardHeader
                  image={<Avatar name={item.Commenter} />}
                  header={
                    <Body1>
                      <b>{item.Commenter}</b> commented
                    </Body1>
                  }
                  description={
                    item.CommentDatetime === item.EditDatetime ? (
                      <Caption1>{formattedDate(item.CommentDatetime)}</Caption1>
                    ) : (
                      <div className="comment-timestamp">
                        <Caption1>
                          {formattedDate(item.CommentDatetime)}
                        </Caption1>
                        <Caption1 style={{ color: "#808080" }}>
                          Edited {formattedDate(item.EditDatetime)}
                        </Caption1>
                      </div>
                    )
                  }
                  action={
                    <div className="action-buttons">
                      <Button
                        appearance="transparent"
                        icon={<Edit />}
                        onClick={(e) =>
                          handleEditComment(e, item.CommentId, item.Comment)
                        }
                      />
                      <Dialog>
                        <DialogTrigger disableButtonEnhancement>
                          <Button
                            appearance="transparent"
                            icon={<Delete />}
                            onClick={() => handleDeleteComment(item.CommentId)}
                          />
                        </DialogTrigger>
                        <DialogSurface>
                          <DialogBody>
                            <DialogTitle
                              action={
                                <DialogTrigger action="close">
                                  <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<DismissRegular />}
                                  />
                                </DialogTrigger>
                              }
                            ></DialogTitle>
                            <DialogContent>
                              Are you sure you want to delete the following
                              comment:
                              <br />"{obsoleteComment}"
                            </DialogContent>
                            <DialogActions>
                              <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                              </DialogTrigger>
                              <Button
                                appearance="primary"
                                onClick={() =>
                                  deleteComment.mutate(obsoleteCommentID)
                                }
                              >
                                Delete
                              </Button>
                            </DialogActions>
                          </DialogBody>
                        </DialogSurface>
                      </Dialog>
                    </div>
                  }
                />
                {isEditable && item.CommentId === editedCommentId ? (
                  <div className="editable-comment-container">
                    <Textarea
                      className="editable-textarea"
                      value={editedComment}
                      onChange={(e) =>
                        handleCommentInput(e.target.value, "edit")
                      }
                    />
                    <div className="edit-actions">
                      <Button
                        appearance="secondary"
                        onClick={() => setIsEditable(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        appearance="primary"
                        onClick={() => onSubmitComment("edit")}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <CardPreview className="card-preview">
                    <div>{item.Comment}</div>
                  </CardPreview>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
